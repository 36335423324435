import { NavLink as Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro'
import React from 'react'
import { Squash as Hamburger } from 'hamburger-react'

function ToggleNavbar(){
  const navbar = document.getElementById("navbar");

  navbar.classList.toggle("collapsed");
}

export const Nav = styled.nav`
  display: flex;
  justify-content: center;

  .hamburger-react {
    display: none;
    height:40px;
    padding: unset;

    @media screen and (max-width: 768px) {
      display: block;
      position: absolute !important;
      top: 0;
      right: 20px;
      transform: translateY(50%) !important;
      font-size: 1.8rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    display:block;

    #logo {
      margin-right: auto;
      display: block;
      width: fit-content
    }

    div.nav-items {
      overflow: hidden;
      display: none;
    }

    &.collapsed {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 100;
      top: 0;
      background-color: #F0F9FF;

      div.nav-items {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: grid;
        a {
          padding: 6px 1.5rem;
          font-size: 2rem;
          text-transform:uppercase;
        }
      }
    }
  }
`;
  
export const NavLink = styled(Link)`
  ${tw`text-dgray`};

  align-items: center;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0 1.5rem;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  &.active, &:hover {
    ${tw`text-blue-20`};
  }
`;
  
export const NavMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;

  @media screen and (max-width: 768px) {
    width: fit-content
  }
`;

export const Bars = () => {
  return (
      <Hamburger onToggle={ToggleNavbar}/>
  )
}