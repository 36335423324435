import React from 'react';
import Helmet from 'react-helmet'
import Lottie from 'lottie-react';
import Button from '../components/Button';
import kontakt from '../assets/lottie/kontakt.json';
import { MailOpenSharp, CallSharp, LogoFacebook } from 'react-ionicons'
import { useAlert } from 'react-alert'

var sooker = false;

function sendSooknad(alert) {
	var navn = document.getElementById("navn");
  var tlfNummer = document.getElementById("tlf");
  var email = document.getElementById("email");
  var beskrivelse = document.getElementById("beskrivelse");

	if (!navn.value || !tlfNummer.value || !email.value) {
		alert.show("Du må fylle ut alle feltene :)", {type:"error"});
		return
	}

	if (tlfNummer.value.toString().length < 8) {
		alert.show("Du må fylle ut telefon nummeret ditt riktig.", {type:"error"});
		return
	}

	if (!email.value.includes(".") || !email.value.includes("@")) {
		alert.show("Du må fylle ut emailen din riktig.", {type:"error"});
		return
	}

	if (sooker) {
		alert.show("Du må vente til svar på forespørselen først.", {type:"error"});
		return
	}

	sooker = true;

  var http = new XMLHttpRequest();
  var url = 'kontakt.php';
  http.open('POST', url, true);

  http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

  http.onreadystatechange = function() {
      if(http.readyState === 4 && http.status === 200) {
        var result = http.responseText;
        var type = "error";

        if (result === "error") {result = "Noe gikk galt. Prøv igjen senere."}
        if (result === "success") {result = "Melding motatt, vi tar kontakt så fort som mulig."; type = "success"; document.getElementById("kontakt").reset(); }

        alert.show(result, {type:type});
        sooker = false;
      }
  }
  http.send("n=" + navn.value + "&t=" + tlfNummer.value + "&e=" + email.value + "&b=:" + beskrivelse.value);
}

const Kontakt = () => {
  const alert = useAlert()

  return (
    <>
    <Helmet>
      <title>Kontakt - Stellio AS</title>
      <meta name="description" content="Ta kontakt og få et uforpliktende tilbud, vi ser fram til å høre fra dere!"/>
    </Helmet>
    <section>
      <h1 class="text-blue-20 text-3xl text-center pt-8 pb-2">Kontakt oss</h1>

      <div class="bg-blue-100">
        <div class="container mx-auto p-4 pt-8">
          <div className="w-full sm:flex gap-2 ml-auto mr-auto">
            <div className="bg-white-25 p-2 w-full h-fit ml-auto mr-auto mb-2 sm:w-fit sm:mr-0 sm:mb-0">
              <a href="tel:91905917" target="_" className="flex justify-center md:justify-start hover:opacity-70"><CallSharp color="#34ae34" className="mr-1"/>919 05 917</a>
              <a href="mailto:kontakt@stellio.no" target="_" className="flex justify-center md:justify-start hover:opacity-70"><MailOpenSharp color="#0064ff" className="mr-1"/>kontakt@stellio.no</a>
              <a href="https://www.facebook.com/stellioas" target="_" className="flex justify-center md:justify-start hover:opacity-70"><LogoFacebook color="#4267B2" className="mr-1"/>Facebook</a>
            </div>

            <div className="p-2 pt-0 w-full mr-auto ml-auto md:w-96 sm:ml-0">
              <form id="kontakt" onSubmit={e => {
                  e.preventDefault();
                  sendSooknad(alert);
                }}>
                <p>Navn</p>
                <input id="navn" type="text" placeholder="Navn Navnesen" name="navn" className="w-full p-1 mb-1" required/>
                <p>Telefon</p>
                <input id="tlf" type="text" placeholder="12345678" name="tlf" className="w-full p-1 mb-1"/>
                <p>Email</p>
                <input id="email" type="text" placeholder="navn@eksempel.no" name="email"  className="w-full p-1 mb-1"/>
                <p>Beskrivelse</p>
                <textarea id="beskrivelse" placeholder="Litt om hva det gjelder." name="beskrivelse" className="w-full p-1 mb-1" required/>
                <Button type="submit" name="Kontakt oss" className='ml-0 bg-blue-20 mt-2 mb-0'/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container mx-auto p-2">
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
          <div class="flex p-2">
            <div class="place-self-center ml-auto mr-auto">
              <h2 className="text-5xl text-blue-20 mt-2">Vi ser fram til å høre fra deg!</h2>
              <h2 className="text-5xl text-blue-50">👋</h2>
            </div>
          </div>

          <div class="flex p-20 lg:pt-10"><Lottie animationData={kontakt} loop={true} className="ml-2 place-self-center" alt="kontakt"/></div>
        </div>
    </section>
    </>
  );
};
  
export default Kontakt;