import React from "react";
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';

function FeaturesList(props) {
    const features = props.features;
    return (
        <ul>
        {features.map((number) =>
            <li className="flex mb-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill mr-2 mt-auto mb-auto" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
          </svg>{number.toString()}</li>
        )}
        </ul>
    );
}

function PakkerKort(props) {
    const navigate = useNavigate();

    return <div className={'transition ease-in-out duration-150 text-left p-2 ml-auto mr-auto'}>
        <div>
            {props.recommendation ? 
            (<div className="text-center p-1 w-fit ml-auto mr-auto rounded-md pl-2 pr-2 mb-3 bg-blue-50">Bestselger</div>) : 
            (<div className="lg:h-11"/>)}
            <h3 className="text-2xl text-blue-10 text-center">{props.title}</h3>
            <h2 className="text-black-255 text-3xl text-center">{props.pris}</h2>
            <h5 className="text-center text-sm mb-2">Eks. mva.</h5>
            <FeaturesList features={props.features} />
            <center><Button onClick={() => {navigate('/kontakt'); setTimeout(() => {document.getElementById("beskrivelse").value = "Hei, jeg er interessert i " + props.title + " pakken for hjemmeside."}, 10) }} name='Bestill' className='bg-green-200'></Button></center>
        </div>
    </div>;
}

export default PakkerKort;