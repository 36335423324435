import React from 'react';
import Helmet from 'react-helmet'
import PakkerKort from '../components/PakkerKort';
import TjenesteKort from '../components/TjenesteKort';
import Lottie from 'lottie-react';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import planer from '../assets/lottie/avtale.json';
import seo from '../assets/svg/tjenester/seo.svg';
import hosting from '../assets/svg/tjenester/radar.svg';
import design from '../assets/svg/tjenester/pen.svg';


const Tjenester = () => {
  return (
    <>
    <Helmet>
      <title>Tjenester - Stellio AS</title>
      <meta name="description" content="Bestill en av våre pakker, eller ta kontakt for et uforpliktende tilbud!"/>
    </Helmet>

    <h1 class="text-blue-20 text-3xl text-center pt-8 pb-1">Hjemmeside</h1>

    <section>
      <div className="container mx-auto">
        <div className="gap-2 p-4 w-fit grid-cols-1 md:grid md:w-auto ml-auto mr-auto md:grid-cols-2 lg:grid-cols-3 xl:p-4 mb-2">
          <TjenesteKort title='Design' text="Vi sørger for at nettsiden deres er designet på en brukervennlig og moderne måte." icon = {design}/>
          <TjenesteKort title='SEO Optimalisering' text="For at dere skal dukke opp i søkemotorer er det viktig at hjemmesiden er optimalisert for dette." icon = {seo}/>
          <TjenesteKort title='Hosting & domene' text="Vi er eksperter innen hosting og ordner en løsning som er driftsikker og rimelig etter behov." icon = {hosting}/>
        </div>

        {/* <h1 class="text-blue-20 text-3xl text-center pt-2 pb-2">Nytt foretak?</h1>

       <h2 class="text-blue-40 text-2xl text-center pt-2 mb-6">Da kan du få 20% avslag på ny hjemmeside!</h2> */}
      </div>
    </section>

    <section>
      <div class="bg-blue-100">
        <h2 class="text-blue-40 text-2xl text-center pt-2">Pakker</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:p-10 xl:pt-2 xl:pb-2 gap-2 p-2">
          <PakkerKort title="Landing" pris="12 990" features={["Landingside", "CMS", "Gratis SSL", "Driftsavtale", "Full opplæring", "Responsive"]}/>
          <PakkerKort title="Pro" pris="24 990" recommendation={true} features={["Landingside", "Undersider", "Kategorisider", "CMS", "Gratis SSL", "Driftsavtale", "Full opplæring", "SEO Optimalisering", "Responsive"]}/>
          <PakkerKort title="Premium" pris="35 990" features={["Landingside", "Undersider", "Kategorisider", "CMS", "Gratis SSL", "Driftsavtale", "Full opplæring", "SEO Optimalisering", "Skreddersydd", "Responsive"]}/>
        </div>
      </div>
    </section>

    <section class="container mx-auto p-4">
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
          <div class="flex p-2 mt-2">
            <div class="place-self-center ml-auto mr-auto">
              <h2 className="text-5xl text-blue-20">Større planer?</h2>
              <h2 className="text-5xl text-blue-50">Få uforpliktende tilbud</h2>
              <Link to='/kontakt'>
                <Button name='Kontakt' className='bg-green-200 ml-0'/>
              </Link>
            </div>
          </div>

          <div class="flex"><Lottie animationData={planer} loop={true} className="ml-2 p-2 place-self-center" alt="ambisiøs"/></div>
        </div>
    </section>
    </>
  );
};
  
export default Tjenester;