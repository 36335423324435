import React from 'react';
import FooterLink from './FooterElements';

const Footer = () => {
  return (
    <>
    <div className="bg-white-255 p-4 shadow">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
        <div className="flex">
          <div className="mt-auto mb-auto">
            <h3 className="text-2xl uppercase text-blue-20">Stellio AS</h3>
            <h3 className="text-xl uppercase text-blac-255">Org Nr 925 900 850</h3>
          </div>
        </div>

        <div>
          <h3 className="text-2xl text-blue-20">Kontakt</h3>
          <FooterLink href="mailto:kontakt@stellio.no" text="kontakt@stellio.no"/>
          <FooterLink href="tel:91905917" text="919 05 917"/>
        </div>

        <div>
          <h3 className="text-2xl text-blue-20">Nyttige Lenker</h3>
          <FooterLink href="https://www.facebook.com/stellioas" target="_" text="Facebook"/>
          <FooterLink to="/personvern" text="Personvern"/>
        </div>
      </div>
    </div>
    </>
  );
};
  
export default Footer;