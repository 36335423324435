import React from "react";

function Anmeldelser(props) {
    let childs = [];
    for (let i = 0; i < props.anmeldelser.length; i++) {
        let anmeldelse = props.anmeldelser[i];
        childs.push(
        <div className={'w-full md:flex shadow-xl'} style={{backgroundColor:"#f7f7f7"}}>
            <div className="w-full h-60 md:h-auto md:p-20" style={{"backgroundImage":`url(${anmeldelse.img})`, "backgroundPosition":"right center", "backgroundSize":"cover"}}></div>
            <div className="h-full p-2">
                <p>{anmeldelse.text}</p>

                <div className="w-full md:mt-auto pt-2">
                    <span className="text-xl mt-auto text-blue-20">{anmeldelse.navn}</span>
                    <h4 className="text-md">{anmeldelse.bedrift}</h4>
                </div>
            </div>
        </div>)
    }

    return <div className={'ml-auto mr-auto max-w-sm md:max-w-xl xl:max-w-2xl'}>
        {childs}
    </div>;
}

export default Anmeldelser;