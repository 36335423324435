import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/png/128b.png';

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
} from './NavbarElements';

function DisableNavbar(){
  const burger = document.getElementsByClassName("hamburger-react");
  const navbar = document.getElementById("navbar");

  if (navbar.classList.contains("collapsed") && burger && burger[0]) {burger[0].click()};
}

const Navbar = () => {
  const location = useLocation();

  useEffect(() => {
    DisableNavbar();
  }, [location]);

  return (
    <>
      <Nav id="navbar">
        <Bars/>
        <Link to="" id="logo"><img src={Logo} className="ml-2 p-2 h-20 w-20 hover:opacity-75 transition ease-in-out" alt="logo" /></Link>
        <NavMenu className="nav-items">
            <NavLink to='/'>
              Hjem
            </NavLink>
            <NavLink to='/referanser'>
              Referanser
            </NavLink>
            <NavLink to='/tjenester'>
              Tjenester
            </NavLink>
            <NavLink to='/kontakt'>
              Kontakt
            </NavLink>
        </NavMenu>
      </Nav>
    </>
  );
};
  
export default Navbar;