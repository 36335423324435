import React from "react";
import tw, { styled } from 'twin.macro'
import Button from '../components/Button';

export const RefNavButton = styled.button`
    ${tw`text-black-255 text-center mt-8 ml-4 mr-4 font-medium`};
    position: relative;

    &:hover, &.active, &active {
        ${tw`text-blue-20 pb-0`};

        :before {
            width: 100%;
        }
    }

    :before {
        position: absolute;
        content: '';
        left: 0;
        height:  2px;
        bottom: -2px;
        width: 0;
        transition: ease-in-out .2s;
        ${tw`bg-blue-20`};
    }
`;

var techCol = {"Bootstrap":"#7911F7", "ReactJs":"#61DAFB", "Tailwindcss":"#38bdf8", "CssThree":"#264DE4","Html5":"#E44D26", "Wordpress":"#21759b", "Php":"#474A8A", "Javascript":"#f0db4f", "Mysql":"#00758F", "Github":"#171515", "Csharp":"#6a1577", "Lua":"#000081", "Docker":"#0db7ed", "Ubuntu":"#dd4814", "Vuedotjs":"#42b883"};

function StackList(props) {
    const keys = Object.keys(props.stack);
    let childs = [];

    keys.forEach((key, index) => {
        let Elem = props.stack[key];
        childs.push(<Elem style={{color:techCol[key], marginLeft:'.3rem', marginRight:'.3rem'}}/>)
    });

    return (
        <div className="inline-flex text-black-255 mt-4 mb-2">
            {childs}
        </div>
    );
}

function RefProsjekt(props) {
    return <div className={'bg-white-255 max-w-sm overflow-hidden text-blue-20 text-center'}>
        <div className="h-40 bg-bgray flex justify-center items-center"><img alt="logo" class="w-auto h-24" className={props.data.img_class} src={props.data.image}/></div>
        <h1 className="text-xl p-1 pb-0 mt-1 font-medium">{props.data.name}</h1>
        <p className="text-black-255 p-4 h-fill pt-1 pb-1">{props.data.description}</p>
        <StackList stack = {props.data.stack}/>
        <div className="mt-auto">
            <a href={props.data.link} target="_"><Button name='Besøk' className='bg-blue-20 ml-0 mt-1.5 mb-2'/></a>
        </div>
    </div>;
}

export default RefProsjekt;