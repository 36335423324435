import React from "react";
import tw, { styled } from 'twin.macro'
import { MailOutline, MailUnreadOutline } from 'react-ionicons'

export const AlertBox = styled.div`
    ${tw`bg-dgray90 text-white-255 p-3 rounded-md m-1 mb-0`};

    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: all;
`;

function Popup({ message, options, style, close }) {
    return (
    <AlertBox>
        {options.type === "success" && <MailOutline className="mr-2" color="green"/>}
        {options.type === "error" && <MailUnreadOutline className="mr-2" color="red"/>}

        <span className="mr-2">{message}</span>

        <button className="cursor-pointer" onClick={close}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
  <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
</svg></button>
    </AlertBox>);
}

export default Popup;