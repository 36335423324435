import React from 'react';
import { Link } from 'react-router-dom';

const FooterLink = (props) => {
  const className = "block text-black-255 ml-1 hover:ml-2 hover:animate-pulse hover: ease-in duration-100 font-medium"
    return (
      props.to ? <Link className={className} to={props.to}>{props.text}</Link> : <a className={className} target={props.target} href={props.href}>{props.text}</a>
    );
  };
    
export default FooterLink;