import React from 'react';
import Helmet from 'react-helmet'
import Button from '../components/Button';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import { ReactJs, Bootstrap, Tailwindcss, CssThree, Html5, Wordpress, Php, Javascript, Mysql, Github, Csharp, Lua, Docker, Ubuntu, Vuedotjs } from "@icons-pack/react-simple-icons";
import RefProsjekt, { RefNavButton } from '../components/ReferanserNavElements';
import rakett from '../assets/lottie/rakett.json';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

const PortfolioCategories = ["Alle", "Hjemmeside", "Egne prosjekter"]
const Portfolio = []

function AddPortfolioItem(category, name, description, link, image, stack, img_class) {
  Portfolio.push({category, name, description, link, image, stack, img_class})
}

AddPortfolioItem("Hjemmeside", "Ni-Hao AS", "Modernisering av forrige nettsider fra bunn, vi brukte bootstrap og PHP.", "https://www.ni-hao.no", "/assets/png/nihao.png", {Bootstrap, Php, Javascript, CssThree, Html5}, "h-auto")
AddPortfolioItem("Hjemmeside", "Stærsk Treningstudio", "One pager skrevet i TailwindCSS, moderne, simpel og rask.", "https://www.stersk.no", "/assets/jpg/stersk.jpeg", {Tailwindcss, CssThree, Html5})
AddPortfolioItem("Egne prosjekter", "Radon Hosting", "Hosting service for spillservere, vi har servere i USA og England som vi leier ut til kunder fra hele verden.", "https://radon.host", "/assets/png/radon.png", {ReactJs, CssThree, Html5, Php, Javascript, Mysql, Docker, Ubuntu, Bootstrap}, "h-20")
AddPortfolioItem("Egne prosjekter", "Hellstorm Networks", "Samfunn for spillere, vi hadde servere for Garry's Mod og Rust, totalt hadde vi over 15 tusen spillere.", "https://web.archive.org/web/20220522012251/https://hellstorm.io/", "/assets/png/hellstorm.png", {Vuedotjs, Mysql, CssThree, Html5, Php, Csharp, Lua})
AddPortfolioItem("Egne prosjekter", "Stromic Portfolio", "Portfolio som ble brukt til freelancing som spill og web utvikler, nettsiden er utviklet med bootstrap.", "https://stromic.dev", "/assets/png/stromic.png", {CssThree, Html5, Bootstrap})

function CreateCategories(){
  return (
    PortfolioCategories.map((category, k) => 
      <RefNavButton className={k === 0 ? "active" : ""} onClick={()=>FilterPortfolio(category)}>{category}</RefNavButton>
    )
  )
}

function FilterPortfolio(cat) {
  const cat_div = document.getElementById("ref-cats");
  const items_div = document.getElementById("ref-items");

  let items_childs = items_div.children;
  for (let i = 0; i < items_childs.length; i++) {
    let child = items_childs[i];
    const child_cat = Portfolio[i].category;

    child.style.display = (child_cat === cat || cat === PortfolioCategories[0]) ? "" : "none";
  }

  let cat_childs = cat_div.children;
  for (let i = 0; i < cat_childs.length; i++) {
    let child = cat_childs[i];
    
    if (child.innerText === cat) {
      child.classList.add("active");
    } else {
      child.classList.remove("active");
    }
  }
}

function CreatePortfolioItems() {
  return (
    Portfolio.map((item) =>
      <RefProsjekt className="text-blue-20 text-3xl text-center pt-8" data-value={item.category} data = {item}/>
    )
  );
}

const Referanser = () => {
  return (
    <>
    <Helmet>
      <title>Referanser - Stellio AS</title>
      <meta name="description" content="Vi har jobbet på små og store prosjekter, ta en titt på noen av våre referanser!"/>
    </Helmet>

    <h1 className="text-blue-20 text-3xl text-center pt-8">Referanser</h1>
    <h1 className="text-black-255 text-xl text-center pb-8 font-medium">En smakebit av hva vi leverer!</h1>

    <section className="bg-blue-100 -skew-y-1 mb-10">
      <div className="bg-blue-100 h-20 skew-y-1 w-full absolute -bottom-10 left-0"/>
      <div className="relative z-10 skew-y-1">
        <div className="container mx-auto">
          <div id="ref-cats" className="flex justify-center">
            <CreateCategories/>
          </div>

          <div id="ref-items" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-12 gap-4 justify-items-center">
            <CreatePortfolioItems/>
          </div>
        </div>
      </div>

    </section>

    <h1 className="text-black-255 text-3xl text-center p-8 pt-14">Teknologier</h1>
    <section className="bg-white-255 h-28 mb-10 m-12 ml-0 mr-0 mt-0">
        <Splide options={ {
          type:'loop',
          autoWidth:true,
          gap:'1rem',
          autoplay:true,
          focus:'center',
          pauseOnHover:false,
          resetProgress:false,
          pagination:false,
        } }>
          <SplideSlide><ReactJs size='' className="h-16 m-6" style={{color:"#61DAFB"}}/></SplideSlide>
          <SplideSlide><Bootstrap size='' className="h-16 m-6" style={{color:"#7911F7"}}/></SplideSlide>
          <SplideSlide><Tailwindcss size='' className="h-16 m-6" style={{color:"#38bdf8"}}/></SplideSlide>
          <SplideSlide><CssThree size='' className="h-16 m-6" style={{color:"#264DE4"}}/></SplideSlide>
          <SplideSlide><Html5 size='' className="h-16 m-6" style={{color:"#E44D26"}}/></SplideSlide>
          <SplideSlide><Wordpress size='' className="h-16 m-6" style={{color:"#21759b"}}/></SplideSlide>
          <SplideSlide><Php size='' className="h-16 m-6" style={{color:"#474A8A"}}/></SplideSlide>
          <SplideSlide><Javascript size='' className="h-16 m-6" style={{color:"#f0db4f"}}/></SplideSlide>
          <SplideSlide><Mysql size='' className="h-16 m-6" style={{color:"#00758F"}}/></SplideSlide>
          <SplideSlide><Github size='' className="h-16 m-6" style={{color:"#171515"}}/></SplideSlide>
          <SplideSlide><Csharp size='' className="h-16 m-6" style={{color:"#6a1577"}}/></SplideSlide>
          <SplideSlide><Lua size='' className="h-16 m-6" style={{color:"#000081"}}/></SplideSlide>
          <SplideSlide><Docker size='' className="h-16 m-6" style={{color:"#0db7ed"}}/></SplideSlide>
          <SplideSlide><Ubuntu size='' className="h-16 m-6" style={{color:"#dd4814"}}/></SplideSlide>
          <SplideSlide><Vuedotjs size='' className="h-16 m-6" style={{color:"#42b883"}}/></SplideSlide>
        </Splide>
    </section>

    <section className="container mx-auto lg:pt-20 pb-20 mt-20">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="flex">
          <div className="place-self-center ml-auto mr-auto">
            <h2 className="text-5xl text-blue-20">Bare å sette i gang?</h2>
            <div className="text-left mt-auto">
              <Link to='/kontakt'>
                <Button name='Kontakt' className='bg-green-200 ml-0'/>
              </Link>
              <Link to='/tjenester' >
                <Button name='Pakker' className='bg-blue-20'/>
              </Link>
            </div>
          </div>
        </div>

        <div><Lottie animationData={rakett} loop={true} className="ml-2 p-2 place-self-center" alt="rakett"/></div>
      </div>
    </section>
    </>
  );
};
  
export default Referanser;