import React from 'react';
import Helmet from 'react-helmet'
import Lottie from 'lottie-react';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import privacy from '../assets/lottie/privacy.json';

const Personvern = () => {
  return (
    <>
    <Helmet>
      <title>Personvern - Stellio AS</title>
      <meta name="description" content="Vi tar personopplysninger på alvor, les vårt personvern."/>
    </Helmet>
    
    <h1 className="text-blue-20 text-3xl text-center pt-8 pb-4">Personvern</h1>

    <section class="bg-blue-100 p-4">
      <div class="container mx-auto pt-2 pb-2">
        <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-20">
          <div class="">
            <h3 className="text-xl text-blue-20 font-medium">Behandling av personopplysninger</h3>
            <p>I forbindelse med henvendelse og eller kjøp hos Stellio AS har du lagt inn en rekke personlige opplysninger, dette inkluderer:</p>
            <p>&#9642;  Ditt navn</p>
            <p>&#9642;  Din adresse</p>
            <p>&#9642;  Ditt telefonnummer</p>
            <p>&#9642;  Din epost adresse</p>
            <p>&#9642;  Ditt orginisasjonsnummer</p>
          </div>

          <div class="">
            <h3 className="text-xl text-blue-20 font-medium">Cookies / informasjonskapsler</h3>
            <p>En cookie er en tekstfil som er lagret i nettleseren din, dette brukes for å kunne gjenkjenne din økt på nettsiden. Ingen personlig informasjon vil bli lagret i informasjonskapslene våre.</p>
            <h5 className="text-md text-blue-20 mt-2 font-medium">Hvor lenge oppbevares cookies?</h5>
            <p>&#9642;  De vil automatisk slettes etter interval.</p>
            <h5 className="text-md text-blue-20 mt-2 font-medium">Hvordan unngå cookies?</h5>
            <p> &#9642;  Du kan blokkere dem på vår nettside.</p>
            <h5 className="text-md text-blue-20 mt-2 font-medium">Hva bruker dere cookies til?</h5>
            <p> &#9642;  Vi førere statistikk over våre besøkere.</p>
          </div>
        </div>
      </div>
    </section>

    <section className="container mx-auto pt-10 p-4 lg:pb-10 lg:p-0">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="flex">
          <div className="place-self-center ml-auto mr-auto">
            <h2 className="text-5xl text-blue-20">Dra tilbake?</h2>
            <div className="text-left mt-auto">
              <Link to='/tjenester'>
                <Button name='Tjenester' className='bg-green-200 ml-0'/>
              </Link>
              <Link to='/' >
                <Button name='Hjem' className='bg-blue-20'/>
              </Link>
            </div>
          </div>
        </div>

        <div><Lottie animationData={privacy} loop={true} className="ml-2 place-self-center p-4 pt-4 lg:p-24" alt="privacy"/></div>
      </div>
    </section>
    </>
  );
};
  
export default Personvern;