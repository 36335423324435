import React from 'react';
import Button from '../components/Button';
import TjenesteListe from '../components/TjenesteListe';
import Anmeldelser from '../components/Anmeldelser';
import Helmet from 'react-helmet';
import Typist from 'react-typist';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import '../assets/css/stellio.css';
import kontaktoss from '../assets/lottie/conference.json';
import building from '../assets/lottie/responsive.json';
import yeewai from '../assets/jpg/yeewai.jpg';

const Home = () => {
  return (
    <>
    <Helmet>
      <title>Digitalisert hverdag - Stellio AS</title>
      <meta name="description" content="Hjemmeside til bedrift? Vi tilbyr alt fra små til store nettsideløsninger skreddersydd etter deres behov - rimelig, sikkert og stabilt."/>
    </Helmet>
    <section className="container mx-auto mt-12 p-4 -mb-20 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
          <div className="place-self-end">
            <h1 className="text-blue-20 text-4xl">
              <Typist>
                Digitalisert Hverdag.
              </Typist>
            </h1>
            <p className="text-black-255 text-lg font-medium">Din digitale partner, vi tar oss av alt som skal til for å komme seg på nett, drifte og sikre servere mot eksterne og interne trusler. Med oss kan dere senke skuldrene og fokusere på det dere er god på, så gjør vi det samme.</p>

            <div className="text-left mt-auto">
              <Link to='/kontakt'>
                <Button name='Kontakt' className='bg-green-200 ml-0'/>
              </Link>
              <Link to='/tjenester' >
                <Button name='Tjenester' className='bg-blue-20'/>
              </Link>
            </div>
          </div>
        <div>
  
        <div><Lottie animationData={building} loop={true} className="ml-2 p-2 place-self-center" alt="work together"/></div>
        </div>
      </div>
    </section>

    <section className="bg-blue-100 text-center pt-20 pb-4 relative mb-20">
      <h2 className="text-3xl text-blue-200 mt-4">Hva tilbyr vi?</h2>
      <div className="container mx-auto">
        <div className="gap-2 p-4 w-fit grid-cols-1 md:grid md:w-auto ml-auto mr-auto md:grid-cols-2 lg:grid-cols-2 xl:p-10">
          <TjenesteListe title="Nettside" liste={["Responsivt Design", "Tilgjengelighet", "Hosting & domene", "SEO Optimalisering", "Oppgradering", "Skreddersydd", "CMS-løsninger", "Design"]}/>
          <TjenesteListe title="IT-Tjenester" liste={["Sikkerhet", "Backup", "Driftsavtale", "Oppgradering", "Fjerning av virus", "Fjernhjelp"]}/>

          {/* <TjenesteKort title='Hjemmesider' text="Vi leverer hjemmesider av ypperst kvalitet, vi holder oss oppdatert på teknologien." icon = {tjeneste_svgs[4]}/>
          <TjenesteKort title='IT-Tjenester' text="Vi har lang erfaring med IT og kan tilby sikkerhet og vedlikehold av systemer." icon = {tjeneste_svgs[0]}/>
          <TjenesteKort title='Design' text="Det er viktig at designet deres har en moderne, sterk og egen personlighet." icon = {tjeneste_svgs[1]}/>
          <TjenesteKort title='SEO Optimalisering' text="Det er viktig at deres nettside dukker opp når man søker med søkemotorer." icon = {tjeneste_svgs[3]}/>
          <TjenesteKort title='Hosting & domene' text="Vi ordner alt som skal til for å få opp dere hjemmeside om det er ønskelig." icon = {tjeneste_svgs[2]}/>
          <TjenesteKort title='God kundeservice' text="Vi har lang erfaring med kundeservice og tilbyr professionell hjelp." icon = {tjeneste_svgs[5]}/> */}
        </div>
      </div>
      <div className="bg-blue-100 h-20 skew-y-1 w-full absolute -bottom-10 left-0"/>
    </section>
    
    <section className="bg-white-255 pb-8 pt-20 -mt-20">
      <div className="container mx-auto text-center pb-4">
        <h2 className="text-3xl text-blue-20 text-center mb-2">Anmeldelser</h2>
        <Anmeldelser anmeldelser = {[{"img":yeewai, "bedrift":"Nihao AS", "navn":"Yee-Wai Cheung", text:"Etter å ha brukt Idium som leverandør av vår hjemmesideløsning i en del år, har vi i 2020 bestemt oss for å bruke et lokalt firma. Da Stellio ved Oliver Nilssen tok kontakt på epost. Var jeg snar med å gi respons. Oliver har vært hjelpsom, imøtekommende og løsningsorientert hele veien. Det har aldri tatt mere enn 5 minutter å få respons fra han, uansett om det er på sms eller epost, og nesten døgnet rundt og 7 dager i uka. Jeg anbefaler Stellio på det sterkeste!"}]}/>
      </div>
    </section>

    <section className="container mx-auto pt-20">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:gap-20">
        <div className="flex lg:p-20">
          <div className="place-self-center ml-auto mr-auto">
            <h2 className="text-5xl text-blue-20">Vi er klare,</h2>
            <h2 className="text-5xl text-blue-50">Er dere klare?</h2>
            <div className="text-left mt-auto">
              <Link to='/kontakt'>
                <Button name='Kontakt' className='bg-green-200 ml-0'/>
              </Link>
              <Link to='/tjenester' >
                <Button name='Tjenester' className='bg-blue-20'/>
              </Link>
            </div>
          </div>
        </div>

        <div>
          <Lottie animationData={kontaktoss} loop={false}/>
        </div>
      </div>
    </section>
    </>
  );
};
  
export default Home;