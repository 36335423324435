import React from "react";
import Tilt from 'react-parallax-tilt'

function TjenesteKort(props) {
    return <div className={'transition ease-in-out duration-150 text-left p-2 ml-auto mr-auto'}>
        <Tilt glareEnable={true} className='bg-white-50 rounded-md p-4 hover:shadow-xl shadow-blue-500/50'>
            <img src={props.icon} className="w-20 h-20 -ml-2" alt={props.title}/>

            <h3 className="text-xl text-blue-10">{props.title}</h3>
            <p className="text-black-255 text-md">{props.text}</p>
        </Tilt>
    </div>;
}

export default TjenesteKort;