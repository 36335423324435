import React from "react";
import tw, { styled } from 'twin.macro'

function ListTjenester(props) {
    const liste = props.liste;
    return (
        <ul>
        {liste.map((tjeneste) =>
            <h2 className="flex mb-1 font-medium">{tjeneste.toString()}</h2>
        )}
        </ul>
    );
}

export const TitleBox = styled.div`
  ${tw`w-12 relative`};

  writing-mode: sideways-lr;
  -webkit-writing-mode: vertical-lr;
`;

function TjenesteListe(props) {
    return <div class={'flex ml-auto mr-auto mt-4'}>
        <TitleBox>
            <h1 class="text-2xl text-blue-10 h-fit mb-auto">{props.title}</h1>

            <div class="bg-black-255 absolute right-1.5 top-0 h-full w-0.5"/>
        </TitleBox>

        <div class="">
            <ListTjenester liste={props.liste} />
        </div>
    </div>;
}

export default TjenesteListe;